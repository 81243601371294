import React from "react"
import Seo from "src/utils/seo"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import ProductGroup from "components/ProductGroup/ProductGroup"

export default function Page() {
    return(
        <React.Fragment>
            <Seo title="PGN Product Groups" />
            <Layout showActions={true} showBulk={false}>
                <ProductGroup page="productgroup"/>
            </Layout>
            <SettingsManager/>
        </React.Fragment>
    )
}