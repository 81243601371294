import React from "react"
import {
    formatNumberToCurrency,
    formatNumberToPercent,
    formatNumber,
} from "src/utils/utils"

export const COLUMNS = [
    {
        keyTop:"pgn",
        labelTop:"Product Group Actions",
        sortTop:true,
        template:''
    },
    {
        keyTop:"state",
        labelTop:"Campaign State",
        sortTop:true,
        template:''
    },
    {
        keyTop:"unitPrice",
        labelTop:"Max.Spend",
        keyBottom:"maxAcos",
        labelBottom:"Max.ACoS",
        sortTop:true,
        sortBottom:true,        
        template:'',
    },
    {
        keyTop:"dailyBudget",
        labelTop:"Budget",
        sortTop:true,
        template:(num) => (formatNumberToCurrency(num)),
    },
    {
        keyTop:"impr",
        labelTop:"Impr",
        sortTop:true,
        template:(num) => (formatNumber(num)),
    },
    {
        keyTop:"clicks",
        labelTop:"Clicks",
        keyBottom:"ctr",
        labelBottom:"CTR",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumber(num1)} <br/> {formatNumberToPercent(num2)}</>,
    },
    {
        keyTop:"spend",
        labelTop:"Spend",
        keyBottom:"cpc",
        labelBottom:"CPC",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToCurrency(num1)} <br/> {formatNumberToCurrency(num2)}</>,
    },
    {
        keyTop:"orders7",
        labelTop:"Orders7",
        keyBottom:"ordersSKU7",
        labelBottom:"(sSKU7)",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumber(num1)} <br/> {formatNumber(num2)}</>,
    },
    {
        keyTop:"orders30",
        labelTop:"Orders30",
        keyBottom:"ordersSKU30",
        labelBottom:"(sSKU30)",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumber(num1)} <br/> {formatNumber(num2)}</>,
    },
    {
        keyTop:"sales7",
        labelTop:"Sales7",
        keyBottom:"salesSKU7",
        labelBottom:"(sSKU7)",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToCurrency(num1)} <br/> {formatNumberToCurrency(num2)}</>,
    },
    {
        keyTop:"sales30",
        labelTop:"Sales30",
        keyBottom:"salesSKU30",
        labelBottom:"(sSKU30)",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToCurrency(num1)} <br/> {formatNumberToCurrency(num2)}</>,
    },
    {
        keyTop:"profit7",
        labelTop:"Profit7",
        keyBottom:"profit30",
        labelBottom:"Profit30",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToCurrency(num1)} <br/> {formatNumberToCurrency(num2)}</>,
    },
    {
        keyTop:"cr7",
        labelTop:"CR7",
        keyBottom:"cr30",
        labelBottom:"CR30",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToPercent(num1)} <br/> {formatNumberToPercent(num2)}</>,
    },
    {
        keyTop:"acos7",
        labelTop:"ACoS7",
        keyBottom:"acos30",
        labelBottom:"ACoS30",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumberToPercent(num1)} <br/> {formatNumberToPercent(num2)}</>,
    },
    {
        keyTop:"roas7",
        labelTop:"RoAS7",
        keyBottom:"roas30",
        labelBottom:"RoAS30",
        sortTop:true,
        sortBottom:true,
        template:(num1,num2) => <>{formatNumber(num1)} <br/> {formatNumber(num2)}</>,
    },
]