import React,{useState,useEffect,useContext} from "react"
import {v4 as uuidv4} from "uuid"

import {API_URL,API_SMART_NAVIGATION} from "src/constants/constants"
import {store} from "src/context/store"
import Table from "components/Table/Table"
import Layout from "components/Layout/Layout"
import SettingsManager from "components/SettingsManager/SettingsManager"
import {COLUMNS} from "./Columns"

import {
    formatNumberToCurrency,
    formatNumberToPercent,
    parseNumber
} from "src/utils/utils"

import SearchFilters from "components/SearchFilters/SearchFilters"

import AddNew from "components/Modals/AddNew/AddNew"
import BulkAction from "components/Modals/BulkAction/BulkAction"
import SmartNavigation from "components/Modals/SmartNavigation/SmartNavigation"
import Edit from "components/Modals/Edit/Edit"
import AdvancedSearch from "components/Modals/AdvancedSearch/AdvancedSearch"

import {Link} from "gatsby"

import DATA from "src/data/Campaign.json"

const ProductGroup = ({page}) => {
    const globalState = useContext(store)

    const [isLoading,setIsLoading] = useState(false)
    const [isParentError,setIsParentError] = useState(false)
    const [data,setData] = useState([])

    const [showChart,setShowChart] = useState(false)

    const [smartNavModal,setSmartNavModal] = useState(false)
    const [editModal,setEditModal] = useState(false)
    const [editModalActive,setEditModalActive] = useState({})
    const [advancedSearchModal,setAdvancedSearchModal] = useState(false)

    const [activeItem,setActiveItem] = useState()

    const [checkboxes,setCheckBoxes] = useState({})

    const [smartNavModalData,setSmartNavModalData] = useState([])

    const handleCheckBoxesChange = (e) => {
        let name = e.target.name
        setCheckBoxes({
            [name]:e.target.checked
        })
    }

    const showSmartNavModal = (item) => {
        setActiveItem(item)
        setSmartNavModal(true)
    }

    const showAdvancedModal = () => {
        setAdvancedSearchModal(true)
    }

    const showEditModal = (item,tab) => {
        setActiveItem(item)
        setEditModal(true)
        setEditModalActive({tab})
    }

    const showEditModalDouble = (e,item,tab,label) => {
        if(e.detail === 2){
            setActiveItem(item)
            setEditModal(true)
            setEditModalActive({
                tab,
                label
            })
        }
    }

    const BADGES = ["e","p","b","a","t"]

    const tableDataTemplate = (item,showColumns) => {
        return(
            <tr key={uuidv4()} data-campaignid={item["campaignId"]} data-campaignname={item["name"]} >
                <td>
                    <input type="checkbox" name={item["campaignId"]} onChange={handleCheckBoxesChange}/>
                </td>
                <td data-label={COLUMNS[0]["labelTop"]} className={"width-200 "+(showColumns["col0"] ? "show":"hide")}>
                    {item["pgn"]}
                    <br/>
                    <span className="icon-container">
                        <i title="View Campaigns: " className="fa fa-bullhorn text-blue"></i>
                        <i title="View Ad Group: " className="fa fa-object-group text-red"></i>
                        <i onClick={() => showSmartNavModal(item)} title="Smart Navigation: " className="fa fa-compass text-black"></i>
                        <i title="View History Log: " className="fa fa-random text-grey"></i>
                        <i onClick={() => showEditModal(item,'rules')} title="Edit Rule Configurations: " className="popover_labels fa fa-cogs text-green"></i>
                    </span> 
                </td>
                <td data-label={COLUMNS[1]["labelTop"]} className={showColumns["col1"] ? "show":"hide"}>
                    {/* <p onClick={(e) => showEditModalDouble(e,item,'edit','state')} className={item["state"]+" hover-link"} title="Double Click to Edit">({item["state"]})</p> */}
                    <br/>
                    <div className="badges-container">
                        {
                            BADGES.map( badge => {
                                return(
                                    item["pgncid_"+badge] !== "0" ?
                                    <Link key={uuidv4()} to={"/campaign/?campaignId="+item["pgncid_"+badge]}>
                                        <span className={"badge bg-green"}>
                                            {badge}
                                        </span>
                                    </Link>
                                    :
                                    <span key={uuidv4()} className={"badge bg-black"}>
                                        {badge}
                                    </span>
                                )
                            })
                        }
                    </div>
                </td>
                <td data-label={COLUMNS[5]["labelTop"]} className={showColumns["col5"] ? "show":"hide"}>
                    <span className="hover-link" onClick={(e) => showEditModalDouble(e,item,'rules','unitPrice')} title="Double Click to Edit">{formatNumberToCurrency(item["unitPrice"]) === "" ? "Unset!":formatNumberToCurrency(item["unitPrice"])}</span>
                    <br/>
                    <span className="hover-link" onClick={(e) => showEditModalDouble(e,item,'rules','maxAcos')} title="Double Click to Edit">{formatNumberToPercent(item["maxAcos"]) === "" ? "Unset!":formatNumberToPercent(item["maxAcos"])}</span>
                </td>
                {
                    COLUMNS.map((col,idx) => {
                        if(typeof col["template"] === "function"){
                            return(
                                <td data-label={col["labelTop"]} key={uuidv4()} className={showColumns["col"+idx] ? "show":"hide"}>
                                    {col["template"](item[col["keyTop"]],item[col?.["keyBottom"]])}
                                </td>
                            )
                        }
                        return null
                    })
                }
            </tr>
        )
    }

    useEffect(() => {
        const searchQuery = {
            intervalCustom:globalState?.state?.customRange || "",
            intervalMonth:globalState?.state?.monthRange || "",
            dateStart:globalState?.state?.dateRange?.[0] || "",
            dateEnd:globalState?.state?.dateRange?.[1] || "",
            stateFilter:globalState?.state?.stateFilter || "",
            searchText:globalState?.state?.searchText || "",
        }
        let searchStr = ""
        Object.keys(searchQuery).forEach(key => searchStr += `${key}=${searchQuery[key]}&` )
        searchStr = searchStr.slice(0, -1)
        const abortCont = new AbortController()

        const formData = new FormData()
        let obj = {}
        obj["action"] = "all"
        obj["page"] = page
        formData.append("request_data_json",JSON.stringify(obj))

        // SMART NAVIGATION MODAL DATA
        fetch(`${API_SMART_NAVIGATION}`,{
            method:"POST",
            credentials:'include',
            signal:abortCont.signal,
            body:formData
        })
        .then(res => res.json())
        .then(response => {
            console.log(response)
            setSmartNavModalData(response)
        }).catch(err => console.log(err))

        // TABLE DATA
        fetch(`${API_URL}&page=${page}&${searchStr}`,{
            method:"GET",
            credentials:'include',
            signal:abortCont.signal
        })
        .then(res => res.json())
        .then(response => {
            if(response.status === "OK"){
                if(response.data !== null){
                    setData(response.data)
                }else{
                    setData([])
                }
            }
            setIsLoading(false)
        }).catch(err => {
            console.log(err.name)
            if(err.name !== "AbortError"){
                setIsParentError(true)
            }
            setIsLoading(false)
        })


        return () => {
            abortCont.abort()            
        }
    }, [
        globalState?.state?.customRange,
        globalState?.state?.monthRange,
        globalState?.state?.dateRange,
        globalState?.state?.stateFilter,
        globalState?.state?.searchText,
    ])

    return(
        <React.Fragment>
            <SearchFilters showAdvancedModal={showAdvancedModal} />
            <Table 
                page={page} 
                // DATA={DATA} 
                searchKeys={["pgn"]}
                DATA={data} 
                checkboxes={checkboxes}
                columns={COLUMNS} 
                setShowChart={setShowChart}
                isParentError={isParentError} 
                isParentLoading={isLoading} 
                tableDataTemplate={tableDataTemplate} 
            />
                            
            { advancedSearchModal && <AdvancedSearch setAdvancedSearchModal={setAdvancedSearchModal} />}
            { editModal && <Edit page={page} setEditModal={setEditModal} item={activeItem} editModalActive={editModalActive} /> }
            { smartNavModal && <SmartNavigation setSmartNavModal={setSmartNavModal} item={activeItem} smartNavModalData={smartNavModalData} /> }
            { globalState?.state?.showAddNewModal && <AddNew/>}    
        </React.Fragment>
    )
}

export default ProductGroup